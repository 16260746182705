p-multiselect {
  position: relative;
  .p-multiselect {
    width: 100%;
    border: 2px solid var(--border-color);
    border-radius: var(--border-radius);
    min-height: 33px;
    background: var(--form-bg);
    position: static;
    .p-multiselect-label-container {
      display: flex;
    }
    .p-multiselect-label {
      padding: 2px 0px 2px 15px;
      color: var(--base-color);
      display: flex;
      align-items: center;
      height: 100%;
      &.p-placeholder {
        color: var(--placeholder-color);
        padding-left: 15px;
      }
    }
    &:not(.p-disabled) {
      &:hover {
        border-color: var(--border-color);
      }

      &.p-focus {
       box-shadow: none;
      }

    }
    &:not(.p-disabled).p-focus,
    &.p-multiselect-open {
      border-color: var(--border-focus-color);
      &:hover {
        border-color: var(--border-focus-color);
      }
    }
    .p-multiselect-trigger {
      .p-multiselect-trigger-icon {
        font-size: var(--sm-fontsize);
        height: 15px;
      }
    }
    .p-multiselect-panel {
      box-shadow: none;
      padding: 8px 0;
      overflow: hidden;
      border: 2px solid var(--border-color);
      border-radius: var(--md-border-radius);
      .p-multiselect-header {
        border-top-right-radius: var(--md-border-radius);
        border-top-left-radius: var(--md-border-radius);
        padding: 6px 16px;
        border-bottom: 0;
        background-color: transparent;
        justify-content: normal;
        flex-wrap: wrap;
        .select-txt {
          color: var(--light-color);
          order: 3;
          flex: 1;
        }
        .p-multiselect-close {
          display: none;
        }
        .p-checkbox {
          order: 2;
        }
        .p-multiselect-filter-container {
          order: 1;
          width: 100%;
          margin-bottom: 8px;
          .p-multiselect-filter-icon {
            left: 16px;
            margin-top: -.5rem;
          }
          input {
            padding: 5px 16px 5px 35px;
          }
        }
      }
      .p-multiselect-items-wrapper {
        .p-multiselect-items {
          padding: 0;
          .p-multiselect-empty-message {
            padding: 6px 16px;
            color: var(--light-color);
          }
          .p-multiselect-item {
            color: var(--base-color);
            padding: 6px 16px;
            white-space: normal;
            &:not(.p-highlight):not(.p-disabled):hover,
            &.p-highlight{
              background-color: var(--hover-color);
            }
            &:not(.p-highlight):not(.p-disabled).p-focus {
              background-color: var(--tab-focus-color);
            }
            &:focus {
                box-shadow: none;
            }
            .p-multiselect-empty-message {
              padding: 6px 16px;
              color: var(--light-color);
            }
          }
        }
      }
    }
    &.p-multiselect-chip {
      .p-multiselect-label {
        display: flex;
        padding-left: 15px;
        gap: 4px;
        &:not(.p-placeholder) {
          padding: 2px 0px 2px 2px;
        }
      }

      .p-multiselect-token {
        padding: 1px 12px;
        background: var(--light-color-2);
        color: var(--base-color);
        border: 2px solid var(--border-color);
        border-radius: var(--border-radius);
        max-width: 100%;
        display: flex;
        flex: inherit;
        min-width: 0;
        .p-multiselect-token-label {
          min-width: 0;
          @extend %ellips;
          flex: 1;
          max-width: 100%;
        }
        .p-multiselect-token-icon {
          font-size: 10px;
          margin-left: 5px;
          .icon-remove {
            &:before {
              color: var(--light-color);
            }
          }
        }
      }
    }
    .p-multiselect-clear-icon {
      width: 12px;
      height: 12px;
      color: var(--light-color);
      margin-top: -0.5rem;
    }
  }
  &.blank-dropdown {
    .p-multiselect-panel {
      .p-multiselect-items-wrapper {
        .p-multiselect-items {
          p-multiselectitem {
            &:last-child {
              border-top: 1px solid var(--border-color);
              display: block;
              padding-top: 8px;
            }
            &:nth-last-child(2) {
              margin-bottom: 8px;
              display: block;
            }
          }
        }
      }
    }
  }
}

