p-radiobutton {
  .p-radiobutton {
    width: 20px;
    height: 20px;
    .p-radiobutton-box {
      border: 2px solid var(--border-color);
      width: 20px;
      height: 20px;
      &:not(.p-disabled).p-focus {
        box-shadow: none;
      }
      &:not(.p-disabled):not(.p-highlight):hover {
        border-color: var(--border-color);
      }
      &.p-highlight,
      &.p-highlight.p-focus,
      &.p-highlight:not(.p-disabled):hover {
        border-color: var(--primary);
        background: var(--white);
        .p-radiobutton-icon {
          background: var(--primary);
        }
      }
    }
  }
}
