.button-primary {
  @include btn();
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-primary));
    }
  }
}
.btn-outline-secondary {
  @include btn($btn-bg: transparent, $border: 2px solid var(--border-color), $btn-text-color: var(--base-color));
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-primary));
    }
  }
}
.btn-white {
  @include btn($btn-bg: var(--white), $border: 2px solid var(--border-color), $btn-text-color: var(--base-color));
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-primary));
    }
  }
}
.btn-icon {
  cursor: pointer;
  background-color: transparent;
  &.icon-remove,
  &.icon-delete {
    &:not(:disabled) {
      &:hover {
        &:before {
          color: var(--hover-primary);
        }
      }
    }

  }
  &.icon-save {
    &:not(:disabled) {
      &:hover {
        &:before {
          color: #2b8fe9;
        }
      }
    }

  }
  &.icon-edit {
    &:not(:disabled) {
      &:hover {
        &:before {
          color: var(--primary-5) !important;
        }
      }
    }
  }

}
.button-wrapper {
  display: flex;
  button {
    ~button {
      margin-left: 16px;
    }
  }
}

