@mixin checkbox-size($size) {
  width: $size;
  height: $size;

  .p-checkbox-box {
    width: $size;
    height: $size;
  }
}

@mixin checkbox-highlight($highlight-color) {
  &.p-highlight {
    border-color: $highlight-color;
    background: $highlight-color;
  }

  &.p-highlight:hover {
    border-color: $highlight-color;
    background: $highlight-color;
  }
}

.p-checkbox {
  @include checkbox-size(16px);
  margin-right: 5px;

  .p-checkbox-box {
    border: 2px solid var(--border-color);
    background: var(--form-bg);
    color: #495057;
    border-radius: 4px;

    .p-checkbox-icon {
      font-size: 9px;
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: var(--border-color);
      }

      @include checkbox-highlight(var(--primary));
      &.p-focus {
        box-shadow: none;
      }
    }
  }

}
