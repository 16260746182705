/* You can add global styles to this file, and also import other style files */

@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/core/main';






