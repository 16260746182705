p-table {
  display: block;
  margin-top: var(--md-space);
  background: var(--white);
  flex: 1;
  &.admin-table {
    margin-top: 0;
  }
  .p-datatable {
    padding: 0 16px 24px;
    display: block;
    font-size: var(--sm-fontsize);

    .icon-loading {
      color: var(--primary);
      font-size: 48px;
    }

    table {
      .empty-message {
        font-size: var(--base-fontsize);
      }

      > .p-datatable-thead {
        > tr {
          > th {
            border: 1px solid var(--light-color-2);
            border-width: 0 0 1px 0;
            color: var(--light-color);
            padding: 5px 10px;
            background-color: var(--white);
            &.p-sortable-column {
              &:focus {
                box-shadow: none;
              }
              .p-icon-wrapper {
                margin-left: 2px;
                color: #909090;
                display: inline-flex;
                font-weight: 400;
                font-family: 'icomoon', sans-serif;
                &:before {
                  content: "\e916";
                }
                &:after {
                  content: "\e917";
                }
                .p-sortable-column-icon {
                  display: none;
                }
              }

              &.p-highlight {
                background-color: var(--white);
                color: var(--light-color);
                box-shadow: none;
              }
              &:not(.p-highlight):hover {
                background-color: var(--white);
                color: var(--light-color);
              }
              &[aria-sort="ascending"] {
                .p-icon-wrapper {
                  &:before {
                    color: var(--primary);
                  }

                }
              }
              &[aria-sort="descending"] {
                .p-icon-wrapper {
                  &:after {
                    color: var(--primary);
                  }
                }
              }
            }

          }
        }
      }

      .p-datatable-tbody {
        > tr {
          &:focus {
            outline: 0;
          }

          &:hover {
            background-color: var(--hover-color);
          }

          td {
            border: 1px solid var(--light-color-2);
            border-width: 0 0 1px 0;
            padding: 8px 10px;
            height: 51px;
            color: var(--base-color);
            cursor: pointer;
            &:nth-child(-n + 2){
              >div {
                display: flex;
                align-items: center;
                .icon-error {
                  margin-left: 6px;
                }
              }
            }
            &.empty-message {
              cursor: auto;
            }

            .name {
              text-decoration: underline;
              color: var(--primary);
            }


            .status {
              border: 2px solid;
              border-radius: var(--border-radius);
              padding: 1px 8px;
              text-align: center;
              font-size: var(--sm-fontsize);
              display: block;
              float: left;
              min-width: 45px;

              &.Draft {
                border-color: var(--border-color);
                background-color: var(--light-color-2);
              }

              &.Completed {
                border-color: var(--primary-2);
                color: var(--primary-2);
                background-color: var(--primary-2-op1);
              }
              &.Published {
                border: 2px solid #9747FF;
                background: rgba(151, 71, 255, 0.10);
                color: #9747FF;
              }

              &.SentToEMU {
                background: #DFEFF6;
                border-color: var(--primary-5);
                color: #00376E;
              }

              &.Invalid {
                background: #FFF4E6;
                border-color: var(--primary-6);
                color: #C74C00;
              }
              &.Attached {
                border-color: #008DB9;
                color: #006382;
                background-color: rgb(0 141 185 / 26%);
              }
            }
            button {
              font-size: var(--md-fontsize);
              padding: 0 5px;
              &:disabled {
                opacity: 0.5;
              }

              &.icon-edit {
                &:before {
                  color: var(--primary-3);
                }
              }
            }
            .table-btn-group {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              button {
                padding: 0 12px;
              }
              .p-inputswitch {
                margin-left: 10px;
              }

            }
          }
        }
      }
    }
  }

  .p-component-overlay {
    background-color: rgb(255 255 255 / 90%);
  }


  &.no-cursor {
    .p-datatable table .p-datatable-tbody > tr td {
      cursor: default;
    }
  }
  .p-datatable-loading-overlay {
    min-height: 500px;
  }
  &.padd-0 {
    .p-datatable {
      padding: 0 0 var(--lg-space);
    }
  }
}

