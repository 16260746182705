p-tabview {
  .p-tabview {
    &.vertical-tab {
      display: flex;
      margin-bottom: var(--lg-space);

      .p-tabview-nav-container {
        min-width: 170px;
        width: 17%;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 100%;
          background-color: var(--border-color);
          width: 2px;
          z-index: 1;
        }

        .p-tabview-nav-content {
          .p-tabview-nav {
            display: flex;
            flex-direction: column;
            border: 0;

            li {
              border-right: 2px solid var(--border-color);
              border-bottom: 2px solid transparent;
              border-top: 2px solid transparent;

              .p-tabview-nav-link {
                border-radius: 0;
                margin: 0;
                border: 0 !important;
                color: var(--base-color);
                font-size: var(--md-fontsize);
                padding: 22px 12px;

                span, app-title {

                  &.invalid {
                    opacity: .5;
                  }
                }

                app-title {
                  display: flex;
                  align-items: center;
                  flex: 1;
                  min-width: 0;
                  span:first-child {
                    flex: 1;
                    min-width: 0;
                    padding-right: 10px;
                  }
                  .measure-items {
                    &:not(.measureStatus) {
                      span:first-child {
                        max-width: calc(100% - 25px);
                      }
                    }
                  }
                }

                &:not(.p-disabled):focus {
                  box-shadow: none;
                }

                .icon-delete {
                  padding-left: 5px;
                  font-size: var(--md-fontsize);
                  margin-left: auto;

                  &:disabled {
                    opacity: .4;
                  }
                }

                .icon-error,
                .icon-check {
                  padding-left: 6px;
                  margin-left: auto;
                }
              }

              &.p-highlight {
                position: relative;
                z-index: 1;
                border-right: 0 !important;
                border-bottom: 2px solid var(--border-color);
                border-top: 2px solid var(--border-color);
                border-left: 4px solid var(--primary);
              }

              &.p-disabled {
                opacity: 0.4;
              }

              &.p-tabview-ink-bar {
                border: 0;
              }
            }
          }
        }
      }

      .p-tabview-panels {
        flex: 1;
        padding: var(--lg-space) var(--lg-space) var(--lg-space) 40px;
        border: 2px solid var(--border-color);
        border-left: 0;
        border-radius: 0;
        min-width: 0;
        color: var(--base-color);

        p-tabpanel[ng-reflect-disabled="true"] {
          opacity: 0.4;
        }
      }
    }
  }
}
