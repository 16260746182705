p-inputswitch {
  .p-inputswitch {
    width: 34px;
    height: 17px;
    display: flex;
    &.p-disabled {
      opacity: 0.5
    }
    .p-inputswitch-slider {
      background: var(--disabled-color);
      &:before {
        width: 15px;
        height: 15px;
        left: 1px;
        margin-top: -7.5px;
      }
    }
    &.p-focus {
      .p-inputswitch-slider {
        box-shadow: none;
      }
    }
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: var(--primary);
      }
      &:not(.p-disabled):hover .p-inputswitch-slider {
        background: var(--primary);
      }
    }
    &:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background: var(--disabled-color);
      }
    }
  }
}
