p-toast {
  p-toastitem {
    display: block;
    margin-top: 10px;
  }
  .p-toast {
    &.p-toast-top-right {
      right: var(--lg-space);
      top: var(--lg-space);
      opacity: 1;

      .p-toast-message-icon {
        display: none;
      }

      .p-toast-message {
        border: 2px solid;
        border-radius: var(--sm-border-radius);
        color: var(--base-color);
        font-size: var(--md-fontsize);
         box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

        .p-toast-message-content {
          padding: var(--md-space);

          .p-toast-message-text {
            margin-left: 0;
            position: relative;

            .p-toast-detail {
              margin-top: 10px;
            }
            .p-toast-summary {
              width: calc(100% - 22px);
            }
          }
        }

        &.p-toast-message-error,
        &.p-toast-message-success,
        &.p-toast-message-info {
          button.p-toast-icon-close {
            color: var(--base-color);
            width: 14px;
            height: 14px;
            min-width: 14px;
            position: absolute;
            right: 16px;

            &:focus {
              box-shadow: none;
            }
          }
        }
        &.p-toast-message {
          &-error {
            background: #FFE9E6;
            border-color: var(--danger-color);
          }

          &-success {
            background: #E3F1EB;
            border-color: var(--primary-2);
          }

          &-info {
            background-color: #DFEFF6;
            border-color: #377EAD;
          }
        }
      }
    }
  }
}
