app-attachments {
  p-fileupload {
    .p-message-error {
      display: none;
    }

    .p-fileupload {
      font-family: var(--font-family);

      .p-fileupload-buttonbar {
        background: transparent;
        padding: 0;
        border: 0;
        border-radius: 0;
        display: flex;
        align-items: center;

        .p-button {
          @include btn($btn-bg: transparent, $border: 2px solid var(--border-color), $btn-text-color: var(--base-color));

          &:hover,
          &:active {
            @include hover-styles(var(--hover-primary));
          }

          &.p-fileupload-choose {
            &.p-focus {
              box-shadow: none;
            }
          }

          .p-button-icon {
            display: none;
          }
          &.p-disabled {
            opacity: 0.6;
          }
          ~ div {
            margin-left: 10px;
          }
        }

      }

      .p-fileupload-content {
        border: 0;
        padding: 0;

        .p-fileupload-files {
          display: none;
        }

        p-progressbar {
          display: none;
        }
      }
    }

    p-image {
      display: flex;
      justify-content: center;

      .p-image {
        display: block;
        width: 50px;
        height: 50px;
        overflow: hidden;
        margin-bottom: 4px;
        >img {
          width: 50px;
          height: 50px;
        }
      }
    }
    &.upload-loading {
      .p-button {
        opacity: 0.6;
        pointer-events: none;
        &:before {
          @include iconMoon("\e90d");
          @include animation('spin 2s infinite linear');
          margin-right: 8px;
          display: inline-block;
          color: #B6BBC7;
        }
      }
    }
  }
}

.error-messages-attachments,
app-attachments.ng-dirty.ng-invalid,
app-attachments.ng-invalid.ng-touched {
  +app-show-control-errors .validation-control-error {
    display: flex;
    align-items: center;
    &:before {
      @include iconMoon("\e909");
      color: var(--danger-color);
      margin-right: 6px;
    }
  }
   .p-fileupload .p-fileupload-buttonbar .p-button {
    border-color: var(--danger-color);
  }
}

.upload-messages {
  .p-message {
    &.p-message-error {
      background-color: transparent;
      border: 0;
      color: var(--danger-color);
      font-size: var(--sm-fontsize);
      margin: 5px 0 0;
      .p-message-icon {
        font-size: var(--sm-fontsize);
        font-family: 'icomoon';
        &:before {
          content: "\e909";
          color: var(--danger-color);
        }

      }
      .p-icon-wrapper {
        display: none;
      }
    }
  }
  .p-message-wrapper {
    padding: 0;
    .p-message-detail {
      margin-left: 5px;
    }
    .p-message-close {
      display: none;
    }
  }
}
.viewMode  {
  .icon-remove,
  .p-fileupload-buttonbar {
    display: none !important;
  }
  p-fileupload {
    display: block;
    padding-left: 15px;
  }
}

