$font-sizes: (
  1: var(--md-fontsize),
  2: var(--base-fontsize),
  3: var(--sm-fontsize)
);

p-editor {
  .p-editor-container {
    .ql {
      &-snow {
        a {
          color: var(--primary);

          &:hover,
          &:focus {
            color: var(--hover-primary);
          }
        }
      }

      &-bold,
      &-link {
        border-left: 1px solid var(--border-color);
      }

      &-underline {
        border-right: 1px solid var(--border-color);
      }
    }

    .p-editor-content.ql-snow {
      border: 2px solid var(--border-color);
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      min-height: 120px;

      .ql-editor {
        background-color: var(--form-bg);
        border-radius: 0 0 var(--border-radius) var(--border-radius);
        min-height: 120px;
        padding: 15px;
        color: var(--base-color);
      }
    }

    .p-editor-toolbar {
      &.ql-snow {
        border-color: var(--border-color);
        border-width: 2px 2px 1px;
        background-color: var(--form-bg);
        border-radius: var(--border-radius) var(--border-radius) 0 0;
        padding: 5px 15px;

        .ql-stroke {
          stroke: var(--base-color);
        }

        .ql-active {
          .ql-stroke {
            stroke: var(--primary);
          }

          .ql-fill {
            fill: var(--primary);
          }
        }

        .ql-picker {
          &.ql-expanded {
            .ql-picker-options {
              border: 2px solid var(--border-color);
              box-shadow: none;
              padding: 8px 0;

              .ql-picker-item {
                color: var(--base-color);

                &:hover {
                  background: var(--hover-color);

                  .ql-stroke {
                    stroke: var(--primary);
                  }
                }

                &.ql-selected {
                  .ql-stroke {
                    stroke: var(--primary);
                  }
                }
              }
            }

            &:not(.ql-icon-picker) {
              .ql-picker-item {
                padding: 7px 16px;
              }
            }
          }

          &.ql-header {
            .ql-picker-item {
              color: var(--base-color);
              font-size: var(--sm-fontsize);

              @for $i from 1 through 3 {
                &[data-value="#{$i}"]::before {
                  font-size: map-get($font-sizes, $i);
                }
              }
            }
          }

          .ql-picker-label {
            color: var(--base-color);
            font-size: var(--sm-fontsize);

            &.ql-selected,
            &.ql-active {
              color: var(--primary);

              .ql-stroke {
                stroke: var(--primary);
              }
            }

            &.ql-expanded {
              .ql-picker-label {
                color: var(--base-color);
              }
            }
          }
        }
      }

      .p-editor-content {
        &.ql-snow {
          border: 2px solid var(--border-color);
        }

        .ql-editor {
          background-color: var(--form-bg);

        }
      }
    }

    &.ql-toolbar {
      .ql-snow.ql-toolbar {
        button.ql-active,
        .ql-picker-label.ql-active {
          color: #000;
        }
      }
    }
  }
}

.viewMode {
  p-editor {
    display: none;
  }
  .editor-view {
    a {
      @include link;
    }
  }
}
