:root {
  --base-color: #08162A;
  --primary:  #D40E14;
  --hover-primary: #AD030F;
  --primary-2: #00855A;
  --primary-2-op1: rgba(0, 133, 90, 0.1);
  --primary-3: #004F95;
  --primary-3-op1: rgba(0, 79, 149, 0.1);
  --primary-4: #FFD400;
  --primary-5: #006AB9;
  --primary-6: #FFA754;
  --danger-color: #e30000;
  --white: #fff;
  --light-color: #787F90;
  --light-color-2: #ECEEF1;
  --border-color: #D5D8E0;
  --light-border-color: #E5E5E5;
  --border-focus-color: #787F90;
  --disabled-color: #B6BBC7;
  --placeholder-color:  #787F90;
  --form-bg: #F4F5F7;
  --hover-color: #F4F5F7;
  --scroll-color: #c5c5c5;
  --scroll-track-color: #f1f1f1;
  --tab-focus-color: #fafafc;

  --font-family:  'Noto Sans', sans-serif;
  --box-shadow:  0px 1px 2px rgba(8, 22, 42, 0.15);
  --border-radius: 32px;
  --sm-border-radius: 6px;
  --md-border-radius: 16px;

  --base-fontsize: 14px;
  --sm-fontsize: 12px;
  --md-fontsize: 16px;
  --fontsize: 18px;
  --lg-fontsize: 20px;
  --xl-fontsize: 24px;

  --lg-space: 24px;
  --md-space: 16px;


}
