.container {
  padding-left: var(--lg-space);
  padding-right: var(--lg-space);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.link {
  @include link;
}
.measure-info-models {
  .grid {
    margin-top: 0;
    margin-bottom: var(--md-space);
    &:last-child {
      margin-bottom: 0;
    }
    [class^="col-"] {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
main {
  padding: 24px 0 56px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card {
  background-color: var(--white);
  padding: var(--lg-space);
}

app-private {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  app-footer {
    margin-top: auto;
  }
}

.viewMode {
  p-button {
    pointer-events: none;
  }
  .field {
    a {
      padding: 5px 15px;
      display: block;
    }
  }

  pre {
    padding: 5px 0px 5px 15px;
    white-space: pre-line;
  }
  .green-eligible {
    pre {
      color: var(--primary-2);
    }
  }
  .red-eligible {
    pre {
      color: var(--primary);
    }
  }
}

app-non-found-page,
app-home-page,
app-measure-create,
app-calculation-modules-list,
app-dropdown-list,
app-measures-list,
app-calculation-module-create,
app-dropdown-create,
app-access-denied-page,
app-public,
app-reports,
powerbi-report,
.reportClass,
.reportClass iframe {
  display: flex;
  flex-direction: column;
  flex: 1;
}
app-public {
  background: var(--white);
  min-height: 100vh;
  @extend %centered;
  padding: 15px;
}
.type-measure {
  padding: 2px 8px;
  border-radius: var(--border-radius);
  border: 2px solid;
  font-size: var(--sm-fontsize);
  display: inline-block;
  &.non-standard {
    border-color: #ed615c;
    color: var(--danger-color);
    background-color: #ffe9e6;
  }
  &.short-list {
    border-color: var(--primary-2);
    background-color: #e5f3ef;
    color: var(--primary-2);
  }
  &.long-list {
    border-color: var(--primary-2);
    background-color: #e5f3ef;
    color: var(--primary-2);
  }
}
.measure-wrapper {
  padding: 24px;
  border: 2px solid var(--border-color);
  border-radius: var(--sm-border-radius);
  display: block;
  overflow: hidden;
  .item {
    margin-bottom: var(--lg-space);

    &:last-child {
      margin-bottom: 0;
    }
  }
  h5 {
    margin-bottom: 8px;
  }

  .calculated-value {
    font-weight: 700;
    color: var(--primary-2);
    font-size: var(--fontsize);
    &.not-calculated {
      color: var(--disabled-color);
    }
  }
}
.completion-wrapper {
  .item {
    margin-bottom: var(--md-space);
    &:last-child {
      margin-bottom: 0;
    }
    div {
      display: flex;
      align-items: center;
    }
    h5 {
      font-weight: 400;
      font-size: var(--base-fontsize);
      min-width: 195px;
      display: inline-block;
      &:after {
        content: ":";
      }
    }
    .calculated-value {
      font-weight: 700;
      color: var(--primary-2);
      font-size: var(--fontsize);
      margin-left: 64px;
      &.not-calculated {
        color: #b6bbc7;
      }
    }
  }
  &.remove-title {
    h4 {
      display: none;
    }
  }
}
app-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1004;
}
.p-image-mask {
  z-index: 1102 !important;
}
.page-loading,
.section-loading {
  position: fixed;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  em {
    font-size: 48px;
    color: var(--primary);
  }
}

.section-loading {
  position: absolute;
  min-height: 300px;
  &.loan-loading {
    position: absolute;
    min-height: unset;
  }
}
.icon-loading {
  @include animation("spin 2s infinite linear");
}
.import-wrapper {
  display: flex;
  align-items: center;
  span {
    flex: 1;
  }
  em {
    margin-left: 7px;
    font-size: 18px;
    cursor: pointer;
  }
}
.grid {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    position: relative;
    user-select: none;
    .p-dropdown {
      pointer-events: none !important;
      user-select: none !important;
    }
  }
}
.access-Denied-wrapper,
.non-found-wrapper {
  background-color: var(--white);
  padding: 100px 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 160px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
  }
  h4 {
    color: var(--light-color);

    font-weight: 400;
  }
  em {
    color: var(--primary);
  }
}
.table-title-wrapper {
  padding: var(--lg-space);
  background-color: var(--white);
}

p-divider {
  .p-divider {
    padding: 8px;
    .p-divider-content {
      span {
        padding: 5px 9px;
        display: block;
        background: var(--white);
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .viewMode pre {
    padding-left: 0;
  }
}
