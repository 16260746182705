
p-accordion {
  .p-accordion {
    p-accordiontab {
      .p-accordion-tab {
        margin-bottom: var(--lg-space);
        border: 2px solid var(--border-color);
        border-radius: var(--sm-border-radius);
        .p-accordion-header {
          &:not(.p-disabled).p-highlight {
            .p-accordion-header-link {
              background-color: transparent !important;
            }
          }
          .btn-outline-secondary {
           margin-right: var(--lg-space);
          }
          a {
            &.p-accordion-header-link {
              font-weight: 700;
              font-size: var(--lg-fontsize);
              color: var(--base-color);
              padding: 11px 24px;
              min-height: 56px;
              background-color: transparent;
              border: 0;
              border-radius: var(--sm-border-radius);
              .pi {
                font-family: 'icomoon', sans-serif;
                font-size: 6px;
                &.pi-angle {
                  &-up {
                    &:before {
                      content: "\e919";
                    }
                  }
                  &-down {
                    &:before {
                      content: "\e91a";
                    }
                  }
                }
              }
            }
          }

          &:not(.p-disabled) .p-accordion-header-link:focus {
            box-shadow: none;
          }

          &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
          &:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
            background: var(--hover-color);
            border-color: var(--border-color);
            color: var(--base-color);
          }
        }
        .p-accordion-content {
          padding: 10px var(--lg-space) var(--lg-space);
          border: 0;
          border-top: 0;
          color: var(--base-color);
          position: relative;
        }
        h4 {
          margin-bottom: var(--md-space);
        }
        &:not(.p-accordion-tab-active) {
          .p-accordion-header {
            &.p-disabled {
              &:not(.p-highlight) {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}

