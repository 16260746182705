/*path  images*/
/*path  images*/
@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

@mixin bgimage($folderName, $name, $extension) {
  $url: "/assets/images/#{$folderName}/#{$name}.#{$extension}";
  background: url($url) no-repeat;
}

%centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin btn($text-transform: capitalize,$border: 2px solid var(--primary), $font-weight: 400,$border-radius: var(--border-radius), $btn-text-color: var(--white), $btn-bg: var(--primary), $padding1: 5px, $padding2: 16px,  $base-fontsize: var(--base-fontsize), $min-width: 75px ) {
  border-radius: $border-radius;
  text-align: center;
  color: $btn-text-color;
  text-transform: $text-transform;
  padding: $padding1 $padding2;
  background: $btn-bg;
  display: inline-block;
  font-size: $base-fontsize;
  font-weight: $font-weight;
  cursor: pointer;
  border: $border;
  min-width: $min-width;
  transition: all .1s;
  &:focus {
    box-shadow: none;
  }
  .p-button-label {
    transition: none;
    font-weight: 400;
  }
  em {
    margin-right: 5px;
    color: var(--primary);
    display: inline-block;
    vertical-align: middle;
  }
  .icon-loading {
    margin-right: 8px;
    display: inline-block;
    color: #B6BBC7;

  }

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}

%ellips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@include keyframes(spin) {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@mixin link {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--hover-primary);
  }
}
@mixin hover-styles($color) {
  background-color: $color;
  color: var(--white);
  border-color: $color;
}
@mixin iconMoon($content) {
  font-family: "icomoon", sans-serif !important;
  content: $content;
}
