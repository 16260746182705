p-timeline {
  .p-timeline {
    font-size: 13px;
    font-weight: 500;

    .p-timeline-event {
      align-items: center;
      min-height: unset;

      .p-timeline-event-opposite {
        padding: 0;
      }
      .p-timeline-event-separator {
        .p-timeline-event-connector {
          background-color: var(--border-color);
          position: absolute;
        }
        .custom-marker {
          border: 2px solid var(--light-color);
          border-radius: 50%;
          min-width: 16px;
          height: 16px;
          box-shadow: none !important;
          position: relative;
          background-color: var(--white);
          z-index: 1;
          &.completed {
            position: relative;
            + .p-timeline-event-connector {
              &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 2px;
                z-index: 1;
              }
            }
          }
        }

        .p-timeline-event-marker {
          .p-timeline-event-connector {
            border-color: var(--light-color);
          }
        }
      }
      .p-timeline-event-content {
        padding: 2px 0 0 0;
        min-height: 36px;
        text-align: center;

      }
      &:last-child {
        flex: 1;
      }
      &:first-child {
        .active {
          border-color: var(--primary-2);
          animation: none;
        }
        .completed {
          border-color:  var(--primary-2);
        }
      }

    }
    @for $i from 1 through 5 {
      .p-timeline-event:nth-child(#{$i}){
        .active {
          animation: 0.1s active-pointprogress ($i * .6s) ease-out forwards;
        }
        .completed {
          animation: 0.1s pointprogress ($i * .6s) ease-out forwards;
          + .p-timeline-event-connector {
            &:before {
              animation: 0.6s progress ($i * 0.6s) ease-out forwards;
            }
          }
        }
      }
    }
  }
}


@keyframes progress {
  0%   {
    width: 0;
  }
  100%  {
    width: 100%;
    background: var(--primary-2);
  }

}
@keyframes active-pointprogress {
  100%  {
    border-color: var(--primary-2);
  }
}
@keyframes pointprogress  {
  100%  {
    border-color: var(--primary-2);
    background-color: var(--primary-2);
  }
}



