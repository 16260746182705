* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  word-break: break-word;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  font-family:  var(--font-family);
}

html,
body {
  min-height: 100vh;
  color: var(--base-color);
  background-color: #F5F5F5;
  font-size: var(--base-fontsize);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

a, button {
  transition: all .15s;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

img {
  max-width: 100%;
  object-fit: cover;
}

.mt {
  &-24 {
    margin-top: var(--lg-space);
  }
}

.mb {
  &-24 {
    margin-bottom: var(--lg-space);
  }
}
.p-lg {
  padding: var(--lg-space);
}
.p-lr-lg {
  padding: 0 var(--lg-space);
}
.p-disabled, .p-component:disabled {
  opacity: 1;
  color: var(--disabled-color);
  @include placeholder-color(var(--disabled-color));
}
.relative {
  position: relative;
}
.grid-md {
  >[class^="col"] {
    &:first-child {
      padding-right: 70px;
    }
    &:last-child {
      padding-left: 70px;
    }
  }
  @media only screen and (max-width: 767px) {
    >[class^="col"] {
      &:first-child {
        padding-right: 0.5rem;
      }
      &:last-child {
        padding-left: 0.5rem;
      }
    }
  }

}
