.p-overlaypanel {
  color: var(--base-color);
  border-radius: var(--md-border-radius);
  box-shadow: 0px 2px 0px #F4F5F7;
  border: 2px solid var(--border-color);
  min-width: 203px;

  &.more-overlay {
    max-width: 391px;
    width: 100%;
    a {
      color: var(--primary);
      &:hover,
      &:focus {
        color: var(--hover-primary);
      }
    }

    .p-overlaypanel-content {
      max-height: 436px;
      overflow: auto;
    }
  }

  &.panel-padding-lr-0 {
    right: 0 !important;
    left: auto !important;
    top: 100% !important;
    margin-top: 5px;

    .p-overlaypanel-content {
      padding: 7px 0;
    }
  }

  &.setting-overlay {
    min-width: 290px;
  }

  &.filter-panel {
    max-width: 1010px;
    width: 100%;
  }

  .p-overlaypanel-content {
    padding: 16px 16px 24px;
  }

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }

}

p-overlay,
.p-overlay {
  .p-dropdown-header {
    .p-dropdown-filter-container {
      .p-dropdown-filter-icon {
        left: 16px;
        top: 16px;
        margin-top: -0.5rem;
      }

      input {
        padding: 5px 16px 5px 35px;
      }
    }
  }
}
