@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?s950pf');
  src:  url('../../assets/fonts/icomoon.eot?s950pf#iefix') format('embedded-opentype'),
  url('../../assets/fonts/icomoon.ttf?s950pf') format('truetype'),
  url('../../assets/fonts/icomoon.woff?s950pf') format('woff'),
  url('../../assets/fonts/icomoon.svg?s950pf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logout:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-delete:before {
  content: "\e904";
  color: var(--danger-color);
}

.icon-error:before {
  content: "\e909";
  color: #d40e14;
}
.icon-word:before {
  content: "\e907";
  color: #006ab9;
}
.icon-remove:before {
  content: "\e906";
  color: #e30000;
}
.icon-pdf:before {
  content: "\e91b";
  color: #ad0b00;
}
.icon-excel:before {
  content: "\e905";
  color: #217346;
}
.icon-doc-img:before {
  content: "\e908";
}
.icon-check:before {
  content: "\e90a";
  color: #00855a;
}
.icon-search:before {
  content: "\e90b";
}
.icon-calendar:before {
  content: "\e90c";
  color: #787f90;
}

.icon-loading:before {
  content: "\e90d";
}

.icon-warning:before {
  content: "\e90e";
  color: #d40e14;
}

.icon-import:before {
  content: "\e90f";
  color: #004f95;
}
.icon-setting:before {
  content: "\e910";
}
.icon-edit:before {
  content: "\e911";
  color: #004f95;
}
.icon-info:before {
  content: "\e912";
  color: #004f95;
}
.icon-save:before {
  content: "\e913";
  color: #004f95;
}
.icon-draggable:before {
  content: "\e914";
}
.icon-DWG:before {
  content: "\e915";
  color: #e95723;
}
.icon-filter-ask:before {
  content: "\e916";
  color: #909090;
}
.icon-filter-desk:before {
  content: "\e917";
}
.icon-lock:before {
  content: "\e918";
  color: #d40e14;
}
.icon-barrow-up:before {
  content: "\e919";
}
.icon-bdown:before {
  content: "\e91a";
}
