p-paginator {
  .p-paginator {
    justify-content: flex-end;
    padding: 0;
    margin-top: 20px;
    border-bottom: 0;
    .p-paginator-current {
      position: static;
      color: var(--base-color);
      height: unset;
      line-height: normal;
      padding: 0;
      margin: 0 20px 0  0;
      cursor: default;
    }

    .p-paginator-pages {
      .p-paginator-page {
        min-width: 24px;
        height: 24px;
        border: 2px solid var(--border-color);
        margin: 0 4px;

        &:focus {
          box-shadow: none;
        }

        &.p-highlight {
          background-color: var(--primary);
          border-color: var(--primary);
          color: var(--white);
          pointer-events: none;
        }

        &:not(.p-highlight):hover {
          background: var(--hover-color);
          color: var(--base-color);
          border-color: var(--border-color);
        }
      }

    }

    .p-paginator-element {
      min-width: 24px;
      height: 24px;
      border: 2px solid var(--border-color);
      margin: 0 4px;
      color: var(--base-color);
      .p-icon {
        width: 12px;
        height: 12px;
      }
      &.p-disabled {
        opacity: 0.5;
      }
      .p-paginator-icon {
        em {
          display: block;
        }
      }
      &:focus {
        box-shadow: none;
      }

      &:not(.p-disabled) {
        &:not(.p-highlight):hover {
          background: var(--hover-color);
          color: var(--base-color);
          border-color: var(--border-color);
        }
      }

    }
    p-dropdown {
      margin-left: var(--lg-space);
      .p-dropdown {
        margin: 0;
        height: unset;
        .p-dropdown-label {
          display: flex;
          padding: 0px 0px 2px 15px;
        }
        .p-dropdown-trigger-icon {
          font-size: 8px;
        }
      }
    }
  }
}
