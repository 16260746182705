p-calendar {
  display: block;
  width: 100%;

  .p-calendar {
    align-items: center;
    color: var(--base-color);
    width: 100%;

    .p-button {
      color: var(--light-color);
      background: var(--form-bg);
      border: 0;
      padding: 0;
      border-left: 0;
      border-radius: 0;
      position: absolute;
      right: 15px;
      width: unset;

      &:enabled:hover {
        background: transparent;
        color: var(--light-color);
        border-color: var(--border-color);
      }

      &:focus {
        box-shadow: none;
      }

      .pi-calendar {
        font-size: var(--md-fontsize);
        &:before {
          @include iconMoon("\e90c");

        }
      }
    }

    .p-inputtext {
      border-radius: var(--border-radius);
    }
    &:not(.p-calendar-disabled).p-focus > .p-inputtext {
      box-shadow: none;
    }
  }


  + app-show-control-errors {
    display: none;
  }

  &.ng-dirty.ng-invalid,
  &.ng-invalid.ng-touched {
    &:after {
      display: none;
    }

    + app-show-control-errors {
      display: block;
    }
  }
}

.p-datepicker {
  border-radius: var(--md-border-radius);
  border: 2px solid var(--border-color);
  border: 0;
  min-width: 256px !important;
  max-width: 256px;
  margin: 3px 0;
  padding: 0.5rem;
  .p-datepicker-group-container {
    border-radius: var(--md-border-radius);
    background-color: var(--white);
  }

  &:not(.p-datepicker-inline) {
   border: 2px solid var(--border-color);
    box-shadow: none;
  }

  .p-yearpicker,
  .p-monthpicker {
    margin: 0.5rem 0;
    &-month,
    &-year  {
      padding: 0.5rem;
      &.p-highlight {
        color: var(--white);
        background: var(--primary);
      }
    }
  }

  .p-datepicker-header {
    border-bottom: 0;
    padding: 0;
    border-radius: var(--md-border-radius) var(--md-border-radius) 0 0;
    .p-datepicker-title {
      button {
        &.p-link {
          padding: 0;
          color: var(--base-color);

          &:enabled:hover {
            color: var(--base-color);
            background-color: transparent;
          }
          &.p-datepicker-month {
            margin-right: 4px;
          }
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

  }

  table {
    &.p-datepicker-calendar{
      margin: 0.5rem 0;
    }
    tr {
      th {
        font-size: 13px;
        color: var(--light-color);
        font-weight: 500;
      }
    }

    td {
      padding: 1px;
      font-size: var(--base-fontsize);


      > span {
        width: 32px;
        height: 32px;
        color: var(--base-color);

        &:focus {
          box-shadow: none;
        }

        &:not(.p-highlight):not(.p-disabled):focus {
          outline: 0 !important;
          box-shadow: none !important;
          background-color: var(--tab-focus-color);
        }
        &.p-highlight {
          color: var(--white);
          background: var(--primary);
        }

        &.p-disabled {
          color: var(--disabled-color);
        }
      }

      &.p-datepicker-today {
        span {
          color: var(--primary);
          background-color: var(--hover-color);

          &.p-highlight {
            color: var(--white);
            background: var(--primary);
          }
        }
      }
    }
  }

  &:not(.p-disabled) {
    table td span:not(.p-highlight):not(.p-disabled):hover {
      background-color: var(--hover-color);
    }

    .p-monthpicker,
    .p-yearpicker {
      &-month,
      &-year {
        &:not(.p-disabled):not(.p-highlight):hover {
          background: var(--hover-color);
        }
        &:not(.p-disabled):focus {
          box-shadow: none;
        }
      }
    }
  }
}
