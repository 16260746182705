.p-tooltip {
  max-width: 200px;
  .p-tooltip-text {
    padding: 16px;
    background: var(--white);
    color: var(--base-color);
    line-height: normal;
    font-size: var(--sm-fontsize);
    border-radius: var(--border-radius);
    border: 2px solid var(--border-color);
  }
  .p-tooltip-arrow {
    display: none;
  }

}





